import {Facebook, Instagram, Linkedin, Twitter, Youtube} from "react-feather";

export default function TermsAndSocial() {
    return (
        <>
            <div className="sidebar_link" style={{marginTop: "0.7rem"}}>
                <a href="https://www.linkedin.com/company/greenside-development-ae/"><Linkedin/></a>
                {/*<a href="#"><Youtube/></a>*/}
                {/*<a href="#"><Twitter/></a>*/}
                <a href="https://www.instagram.com/greenside.ae/" target={'_blank'}><Instagram/></a>
            </div>
            <div className="sidebar_terms">
                <h6>TERM AND CONDITION</h6>
                <p>The content of this website, including but not limited to text, graphics, logos, images,
                    audio clips, digital downloads, and software, is the property of Copy right 2023
                    EC-01-003912/ EC-01-003918or its content suppliers and is protected by international
                    copyright laws.
                </p>
            </div>
        </>
    )
}