import Sidebar from "../components/Sidebar";
import vid from '../assets/videos/01.webm';
import LazyVideo from "../components/LazyVideo";
import TermsAndSocial from "../components/TermsAndSocial";
const Home = () => {

    return (
        <div className="home_container">
            <LazyVideo videoSrc={vid}/>
            <Sidebar/>
            {
                window.innerWidth < 500 ?
                    <div className="footer_content">
                        <p>At Greenside Development, collaboration is key. We partner with teams that share our vision
                            of
                            community giving and prioritizing lifestyle over mere projects. Our focus extends beyond
                            development;</p>
                        <p style={{marginTop : "0.5rem"}}>It's about enriching lives and fostering sustainable, community-centric environments that
                            resonate with our collective values.</p>
                        <TermsAndSocial />
                    </div> : null
            }

        </div>
    )
}

export default Home;