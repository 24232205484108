import {Facebook, Instagram, Linkedin, Menu, Twitter, X, Youtube} from "react-feather";
import logo from '../assets/img/logo.png';
import $ from 'jquery';
import {NavLink} from "react-router-dom";
import MobileMenu from "./MobileMenu";
import {useEffect, useState} from "react";

const Sidebar = () => {
    const [isHome, setIsHome] = useState(true);
    const [isCommunities, setIsCommunities] = useState(false);
    const [isContact, setIsContact] = useState(false);
    // const btn_close_click = () => {
    //     $('#menu').slideUp("slow", function () {
    //         $('.btn_open_container').slideDown("slow");
    //     })
    // }
    // const btn_open_click = () => {
    //     $('.btn_open_container').slideUp("slow");
    //     $('#menu').slideDown("slow")
    // }
    useEffect(() => {
        let href = window.location.href;
        let source = href.split("/");
        source = source[3];
        if (source == null || source == "") {
            setIsHome(true);
        } else {
            setIsHome(false);
        }
        if (source == "communities") {
            setIsCommunities(true);
        } else {
            setIsCommunities(false);
        }
        if (source == "contact") {
            setIsContact(true);
        } else {
            setIsContact(false);
        }

    }, [])
    return (
        <>
            <MobileMenu/>
            <div className="sidebar">

                <NavLink to={'/'} exact className="logo">
                    <img src={logo} alt="Brand Logo"/>
                    {/*<span>GREENSIDE DEVELOPERS</span>*/}
                </NavLink>
                <div className="menu" id="menu">
                    <ul>
                        <li>
                            <NavLink exact to={'/'}>Greenside Development</NavLink>
                        </li>
                        <li>
                            <NavLink exact to={'/communities'}>COMMUNITIES</NavLink>
                        </li>
                        <li>
                            <NavLink exact to={'/project/wadi645'}>WADI 645 ( WADI AL SAFA 3, AL BARARI )</NavLink>
                        </li>
                        {/*<li>*/}
                        {/*    <NavLink exact to={'/team'}>Our Team</NavLink>*/}
                        {/*</li>*/}
                        <li>
                            <NavLink exact to={'/contact'}>Contact</NavLink>
                        </li>
                    </ul>
                </div>
                <div className="sidebar_content">
                    {
                        isHome ? <>
                            <p>At Greenside Development, collaboration is key. We partner with teams that share our
                                vision
                                of
                                community giving and prioritizing lifestyle over mere projects. Our focus extends beyond
                                development;</p>
                            <p style={{marginTop: "0.5rem"}}>It's about enriching lives and fostering sustainable,
                                community-centric environments that
                                resonate with our collective values.</p>
                        </> : null
                    }
                    {
                        isCommunities ? <>
                            <p>At Greenside, our approach centers on community methodology. Our designs and actions are
                                guided by the needs and values of our community. We believe in building spaces that
                                reflect and enhance the essence of our community, ensuring they are inclusive, vibrant,
                                and conducive to collective well-being.
                            </p>
                        </> : null
                    }
                    {
                        isContact ? <>
                            <p>Greenside Development LLC</p>
                            <p>Office No.16, A2Z Business Centre, Mai Tower ,Al Nahda 1 ,Dubai P.O. Box – 22243</p>
                            <a href="mailto:info@greenside.ae">info@greenside.ae</a>
                        </> : null
                    }

                    <div className="sidebar_link" style={{marginTop: "0.7rem"}}>
                        <a href="https://www.linkedin.com/company/greenside-development-ae/"><Linkedin/></a>
                        {/*<a href="#"><Youtube/></a>*/}
                        {/*<a href="#"><Twitter/></a>*/}
                        <a href="https://www.instagram.com/greenside.ae/" target={'_blank'}><Instagram/></a>
                    </div>
                    <div className="sidebar_terms">
                        <h6>TERMS AND CONDITIONS:</h6>
                        <p>The content of this website, including but not limited to text, graphics, logos, images,
                            audio clips, digital downloads, and software, is the property of Copyright 2023
                            EC-01-003912/ EC-01-003918or its content suppliers and is protected by international
                            copyright laws.
                        </p>
                    </div>
                </div>
            </div>

        </>
    )
}
export default Sidebar;