import Sidebar from "../components/Sidebar";
import vid from '../assets/videos/02.webm';
import LazyVideo from "../components/LazyVideo";
import {Facebook, Instagram, Twitter, Youtube} from "react-feather";
import TermsAndSocial from "../components/TermsAndSocial";

const Commnites = () => {
    return (
        <div className="home_container">
            <LazyVideo videoSrc={vid}/>
            {/*<video className="home_background_video" playsInline={true} autoPlay loop muted>*/}
            {/*    <source src={vid} type="video/mp4"/>*/}
            {/*    /!* Provide fallback content in case the video cannot be played *!/*/}
            {/*    Your browser does not support the video tag.*/}
            {/*</video>*/}
            <Sidebar/>
            {/*<div className="footer_content">*/}
            {/*    <p>Greenside Developments, situated in Dubai, features a team passionate about lifestyle, minimalist*/}
            {/*        living, and fostering a strong cultural ethos.*/}
            {/*        We prioritize giving back through meticulously designed spaces, embodying humble luxury.</p>*/}
            {/*</div>*/}
            {
                window.innerWidth < 500 ?
                    <div className="footer_content">
                        <p>At Greenside, our approach centers on community methodology. Our designs and actions are
                            guided by the needs and values of our community. We believe in building spaces that
                            reflect and enhance the essence of our community, ensuring they are inclusive, vibrant,
                            and conducive to collective well-being.
                        </p>
                        <TermsAndSocial />
                    </div> : null
            }
        </div>
    )
}

export default Commnites;