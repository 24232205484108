import Sidebar from "../components/Sidebar";
import vid from '../assets/videos/04.webm';

import LazyVideo from "../components/LazyVideo";
import TermsAndSocial from "../components/TermsAndSocial";
import {toast} from "react-toastify";

const Contact = () => {

    const sendMail = async () => {
        // const url = "http://192.168.242.193/React%20Naitve%20Mobile%20Apps/greenside_development/mail.php";
        const url = "https://initialconcept.roshniwebhost.com/mail.php"
        let name = document.getElementById("name");
        name = name.value;
        let email = document.getElementById("email");
        email = email.value;
        let phone = document.getElementById("phone");
        phone = phone.value;
        if (name === "" || email === "" || phone === "") {
            toast.warn("All fields are required.");
        } else {
            try {
                let button = document.getElementById("btnSubmit");
                button.innerText = "Processing...";
                let formdata = new FormData();
                formdata.append("name", name);
                formdata.append("email", email);
                formdata.append("phone", phone);

                var requestOptions = {
                    method: 'POST',
                    body: formdata,
                    redirect: 'follow'
                };

                await fetch(`${url}`, requestOptions)
                    .then(response => response.text())
                    .then(result => {
                        console.log(result);
                        if (result == 1) {
                            let name = document.getElementById("name");
                            name.value = "";
                            let email = document.getElementById("email");
                            email.value = "";
                            let phone = document.getElementById("phone");
                            phone.value = "";
                            button.innerText = "Submit";
                            toast.success("Your information has been shared with our team.");
                        } else {
                            toast.error("Server Error! Please try again later.")
                        }
                    }).catch(error => console.log('error', error));
            } catch (e) {
                console.log(e)
            }
        }
    }

    return (
        <div className="home_container">
            <LazyVideo videoSrc={vid}/>
            {/*<video className="home_background_video" playsInline={true} autoPlay loop muted>*/}
            {/*    <source src={vid} type="video/mp4"/>*/}
            {/*    /!* Provide fallback content in case the video cannot be played *!/*/}
            {/*    Your browser does not support the video tag.*/}
            {/*</video>*/}
            <Sidebar/>
            {/*<div className="footer_content">*/}
            {/*    <div className="row justify-content-center">*/}
            {/*        <ul className="footer_links">*/}
            {/*            <li>*/}
            {/*                <a href="#"><Facebook/></a>*/}
            {/*            </li>*/}
            {/*            <li>*/}
            {/*                <a href="#"><Youtube/></a>*/}
            {/*            </li>*/}
            {/*            <li>*/}
            {/*                <a href="#"><Twitter/></a>*/}
            {/*            </li>*/}
            {/*            <li>*/}
            {/*                <a href="#"><Instagram/></a>*/}
            {/*            </li>*/}
            {/*        </ul>*/}
            {/*    </div>*/}
            {/*    <p>All copy &copy; rights are reserved by <h4>GREENSIDE DEVELOPMENTS</h4></p>*/}
            {/*</div>*/}

            <div className="contact-form">
                <h3 className="title">Get In Touch</h3>
                <div className="input-block">
                    <label>Full Name <code>*</code></label>
                    <input type="text" id='name' placeholder={'Enter Full Name'}/>
                </div>
                <div className="input-block">
                    <label>Email Address <code>*</code></label>
                    <input type="text" id='email' placeholder={'Enter your email'}/>
                </div>
                <div className="input-block">
                    <label>Contact No. <code>*</code></label>
                    <input type="text" id='phone' placeholder={'Enter contact no.'}/>
                </div>
                <div className="checkbox-block">
                    <input type="checkbox"/>
                    <span>Keep me updated on news & offers.</span>
                </div>
                <div className="input-block">
                    <button className='btn-submit' id='btnSubmit' onClick={sendMail}>Submit</button>
                </div>
            </div>

            {
                window.innerWidth < 500 ?
                    <div className="footer_content">
                        <p>Greenside Development LLC</p>
                        <p>Office No.16, A2Z Business Centre, Mai Tower ,Al Nahda 1 ,Dubai P.O. Box – 22243</p>
                        <a href="mailto:info@greenside.ae">info@greenside.ae</a>
                        <TermsAndSocial/>
                    </div> : null
            }
        </div>
    )
}

export default Contact;