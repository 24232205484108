import Sidebar from "../components/Sidebar";
import team1 from '../assets/img/team_1.jpeg';

const Team = () => {
    return (
        <div className="team_container">
            <Sidebar/>
            <div className="content">
                <div className="team_cards">
                    <div className="team_card">
                        <div className="card_img">
                            <img src={team1} alt="Team Image"/>
                        </div>
                        <div className="card_content">
                            <p className="name">CEO : XXXXXXXXXX</p>
                            <p className="quotes">"As the CEO of Greenside, I am proud to
                                lead a team dedicated to enhancing
                                lifestyles in Dubai through thoughtful
                                design and a commitment to wellbeing."</p>
                        </div>
                    </div>
                    <div className="team_card">
                        <div className="card_img">
                            <img src={team1} alt="Team Image"/>
                        </div>
                        <div className="card_content">
                            <p className="name">CEO : XXXXXXXXXX</p>
                            <p className="quotes">"As the CEO of Greenside, I am proud to
                                lead a team dedicated to enhancing
                                lifestyles in Dubai through thoughtful
                                design and a commitment to wellbeing."</p>
                        </div>
                    </div>
                    <div className="team_card">
                        <div className="card_img">
                            <img src={team1} alt="Team Image"/>
                        </div>
                        <div className="card_content">
                            <p className="name">CEO : XXXXXXXXXX</p>
                            <p className="quotes">"As the CEO of Greenside, I am proud to
                                lead a team dedicated to enhancing
                                lifestyles in Dubai through thoughtful
                                design and a commitment to wellbeing."</p>
                        </div>
                    </div>
                    <div className="team_card">
                        <div className="card_img">
                            <img src={team1} alt="Team Image"/>
                        </div>
                        <div className="card_content">
                            <p className="name">CEO : XXXXXXXXXX</p>
                            <p className="quotes">"As the CEO of Greenside, I am proud to
                                lead a team dedicated to enhancing
                                lifestyles in Dubai through thoughtful
                                design and a commitment to wellbeing."</p>
                        </div>
                    </div>
                    <div className="team_card">
                        <div className="card_img">
                            <img src={team1} alt="Team Image"/>
                        </div>
                        <div className="card_content">
                            <p className="name">CEO : XXXXXXXXXX</p>
                            <p className="quotes">"As the CEO of Greenside, I am proud to
                                lead a team dedicated to enhancing
                                lifestyles in Dubai through thoughtful
                                design and a commitment to wellbeing."</p>
                        </div>
                    </div>
                    <div className="team_card">
                        <div className="card_img">
                            <img src={team1} alt="Team Image"/>
                        </div>
                        <div className="card_content">
                            <p className="name">CEO : XXXXXXXXXX</p>
                            <p className="quotes">"As the CEO of Greenside, I am proud to
                                lead a team dedicated to enhancing
                                lifestyles in Dubai through thoughtful
                                design and a commitment to wellbeing."</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Team;