import Home from "./pages/Home";
import Commnites from "./pages/Commnites";
import Contact from "./pages/Contact";
import Team from "./pages/Team";
import {Route, Switch} from "react-router-dom";
import Wadi645 from "./pages/project/Wadi645";
import {useEffect} from "react";
import {ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
function App() {
    const setActualVh = () => {
        // Calculate the actual viewport height
        const actualVh = window.innerHeight * 0.01;

        // Set the CSS variable --actual-vh on the root element
        document.documentElement.style.setProperty('--actual-vh', `${actualVh}px`);
    };

    useEffect(() => {
        // Set the initial value when the component mounts
        setActualVh();

        // Add an event listener for resize events to update the height
        window.addEventListener('resize', setActualVh);

        // Clean up the event listener when the component unmounts
        return () => {
            window.removeEventListener('resize', setActualVh);
        };
    }, []);

    return (
        <>
            <ToastContainer/>
            <Switch>
                <Route exact path='/' component={Home}/>
                <Route exact path='/communities' component={Commnites}/>
                <Route exact path='/project/wadi645' component={Wadi645}/>
                <Route exact path='/team' component={Team}/>
                <Route exact path='/contact' component={Contact}/>
            </Switch>
        </>
    );
}

export default App;
